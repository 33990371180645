import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import PolicyUser from "../components/privacy_policy/PolicyUser";
import PolicyCompany from "../components/privacy_policy/PolicyCompany";


const PrivacyPolicy = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
      <div id="about">
        <Helmet>
          <title>Privacy Policy | Personal Data Marketplace | Muna</title>
          <meta
            name="description"
            content="At Muna.io we are accountable for the personal data we handle throughout our ecosystem. We vouch to protect and uphold the trust relationship between the company and its customers."
          />
          <link rel="canonical" href="https://muna.io/privacy-policy" />
          {/* <!-- Google / Search Engine Tags --> */}
          <meta
            itemprop="name"
            content="Privacy Policy | Personal Data Marketplace | Muna"
          />
          <meta
            itemprop="description"
            content="At Muna.io we are accountable for the personal data we handle throughout our ecosystem. We vouch to protect and uphold the trust relationship between the company and its customers."
          />
          <meta
            itemprop="image"
            content="http://static.wixstatic.com/media/66c2f5_d216e103469a4625b69b570d3c6b6da3~mv2.png/v1/fill/w_1206,h_902,al_c/66c2f5_d216e103469a4625b69b570d3c6b6da3~mv2.png"
          />
        </Helmet>

        <Router>
            <Switch>
                <Route path="/privacy-policy/user" exact component={PolicyUser} />
                <Route path="/privacy-policy/company" exact component={PolicyCompany} />
            </Switch>
        </Router>
      </div>
    );
  };
  
  export default PrivacyPolicy;