import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import ghost from "../assets/ghost.svg";

export const PolicyModal = ({ question, answer, text }) => {
  useEffect(() => {}, [question, answer]);
  return (
    <div>
      <Helmet>
        <title>Privacy Policy | Muna | Belgium</title>
        <meta
          name="description"
          content="Know your rights! Consumers get personalised deals and control over their data. "
        />
      </Helmet>
      {/* The Modal  */}
      <div className="modal" id="policyModel">
        <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className=" modal-ghost">
              <img
                src={ghost}
                alt="ghost"
                loading="lazy"
                className="policy-icon"
              />
            </div>
            {/* Modal Header  */}
            <div className="modal-header">
              <div className="custom-header">
                <h3>{question}</h3>
                <h4>{answer}</h4>
              </div>

              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>

            {/* Modal body  */}
            <div
              className="modal-body"
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
