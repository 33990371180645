import React from "react";
import dmslogo from "../../assets/DMSlogo.png";

const AsSeenIn = () => {
  return (
    <div id="benefits" className="benefits as-seen-in">
      <div className="mx-5">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-md-5 col-sm-12">
              <h2 className="mb-0 mt-2">AS SEEN IN</h2>
            </div>
            <div className="col-md-5 col-sm-12">
              <a
                aria-label="dms-logo"
                target="_blank"
                rel="noreferrer"
                href="https://www.datamarketservices.eu/"
              >
                <img src={dmslogo} loading="lazy" alt="dmslogo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsSeenIn;
