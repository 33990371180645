import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import TermsUser from "../components/terms_of_use/TermsUser";
import TermsCompany from "../components/terms_of_use/TermsCompany";

const TermsOfUse = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
      <div id="terms-of Use">
        <Helmet>
          <title>Terms of Use | Personal Data Marketplace | Muna</title>
          <meta
            name="description"
            content="At Muna.io we are accountable for the personal data we handle throughout our ecosystem. We vouch to protect and uphold the trust relationship between the company and its customers."
          />
          <link rel="canonical" href="https://muna.io/terms-of-use" />
          {/* <!-- Google / Search Engine Tags --> */}
          <meta
            itemprop="name"
            content="Terms of Use | Personal Data Marketplace | Muna"
          />
          <meta
            itemprop="description"
            content="At Muna.io we are accountable for the personal data we handle throughout our ecosystem. We vouch to protect and uphold the trust relationship between the company and its customers."
          />
          <meta
            itemprop="image"
            content="http://static.wixstatic.com/media/66c2f5_d216e103469a4625b69b570d3c6b6da3~mv2.png/v1/fill/w_1206,h_902,al_c/66c2f5_d216e103469a4625b69b570d3c6b6da3~mv2.png"
          />
        </Helmet>

        <Router>
            <Switch>
                <Route path="/terms-of-use/user" exact component={TermsUser} />
                <Route path="/terms-of-use/company" exact component={TermsCompany} />
            </Switch>
        </Router>

      </div>
    );
  };
  
  export default TermsOfUse;