import React, { useEffect } from "react";

const TermsUser = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
        <div className="discounts">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10 col-sm-12 pb-5 discounts-left text-justify">

                        <h3 className="font-weight-bold text-center pb-5">Muna.io Terms and Conditions for Users</h3>

                        <div className="terms-section">
                            <p className="subheading">Dear Muna.io user,</p>
                            <p>Here are our terms & conditions (“terms”). Please read them carefully, since you will need to accept these terms to use our application by ticking the box below.</p>
                            <p>These terms are a binding legal contract between you (“user”) and Muna.Io (“we”, “us”, “our”) and govern the use of our mobile application (“service”).</p>
                            <p>These terms govern the use of applications by our users. We have separate terms and conditions for the brands we collaborate with that you can read from here. We also require the brands we work with, and the team members of Muna to sign an ethical data code of conduct. You can find the code of conduct here.</p>
                        </div>

                        <div className="terms-section">
                            <p className="subheading">Definitions</p>
                            <p>
                                User:
                            </p>
                            <p>
                                Brand:
                            </p>
                            <p>
                                Service:
                            </p>
                            <p>
                                Data controller: 
                            </p>
                            <p>
                                Data processor:
                            </p>
                        </div>
                        
                        <div className="terms-section">
                            <p className="subheading">Muna.io application</p>
                            <p>Muna.io is a privacy-first mobile app where our users share their personal data with companies in exchange for promo codes, discounts or cash. Users swipe through offers and decide to accept these or not, by swiping right or left. In practice this means that they will swipe right if they wish to accept an offer, and left if they do not wish to accept an offer. By accepting the promotions, users agree to share their data with the companies behind the offers. For users, Muna helps them to discover new products that they like, control who can access their data, and can exercise their data rights. For example, they can easily ask for data deletion in one simple click.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">User accounts</p>
                            <p>As a user of our app, you will be asked to register with us and provide information about yourself. We only ask for information that is necessary to provide the service to you, like your name and email address. You may give more information to the companies you choose. This information will stay locally on your phone and we have no right to use the data for any other purpose, other than transferring it to the company you choose. You can read more about how we process your data on our privacy policy here. You will also be asked to read and accept the privacy policy. </p>
                            <p>It is your responsibility to ensure accuracy of your personal information and maintain safety of this information as well as confidentiality of your password. You are also responsible for all activities that occur under your account or password. If you think there are any possible issues regarding the security of your account on our application, please inform us immediately so we may address them accordingly.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">Accepting an offer</p>
                            <p>On our application you can discover offers from different companies. If you accept an offer, you choose to share personal information about yourself that is clearly indicated on the offer card. If you do not wish to share this data with the company, please do not accept the company offer. If you accept the company offer, you authorise Muna.io to share the personal data indicated in the offer with the company who made the offer. The company who receives your data will become responsible for maintaining the confidentiality and security of it. In legal terms, the company becomes the data controller and it is allowed to use the for the specified marketing purpose.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">Age restriction</p>
                            <p>Note that we only provide the service to users above 18 years old. By accepting these terms, you confirm that you are 18 years or above. We do not assume responsibility for liabilities related to age misrepresentation.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">Applicable law</p>
                            <p>By using our app, you agree that the laws of Finland will govern these terms and conditions. Any dispute related in any way to your use of our Service shall be arbitrated by the courts of Finland.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">Limitation on liability</p>
                            <p>We are not liable for any damages that may occur to you as a result of your misuse of our services. Muna.Io reserves the right to edit, modify, and change this Agreement at any time.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">Termination</p>
                            <p>You can delete your account on Muna.io anytime for any reason. To do so, please follow the instructions on the application that you can find in the ‘settings’.</p>
                            <p>Please note that we may modify, suspend, or terminate your access to or use of our services anytime for any reason, such as if you violate the letter or spirit of our Terms or create harm, risk, or possible legal exposure for us, our users, or others. We may also disable or delete your account if it does not become active after account registration or if it remains inactive for an extended period of time.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsUser;