import React from "react";
import { PDFExport } from "@progress/kendo-react-pdf";

import diversity from "../../assets/diversity.svg";
import trust from "../../assets/trusticon.svg";
import human from "../../assets/human.svg";
import control from "../../assets/control.svg";
import accountable from "../../assets/accountable.svg";

const CharterPanel1 = () => {
  const container = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <div id="benefits" className="benefits charter-panel1">
      <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        fileName={`Our Charter ${new Date().getFullYear()}`}
        author="Fazilet Kosure"
      >
        <div className="py-5 charter-header">
          <h4>Our charter</h4>
          <h1>FOR ETHICAL DATA</h1>
        </div>

        <div className="benefits charter-panel1">
          <div className=" container" ref={container}>
            <div className="row py-5">
              <div className="col-md-4 col-sm-12 py-5">
                <div className="box">
                  <div className="circle">
                    <img
                      src={human}
                      loading="lazy"
                      className="human"
                      alt="human-centric"
                    />
                  </div>
                  <h2>Human-Centric</h2>
                  <p>
                    Individual interests come first over commercial gain. We
                    build and use human-centric tools to improve the well-being
                    of our communities.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 py-5">
                <div className="box">
                  <div className="circle">
                    <img
                      src={diversity}
                      loading="lazy"
                      className="diversity"
                      alt="diversity"
                    />
                  </div>
                  <h2>Diversity</h2>
                  <p>
                    Our differences are our utmost strength without any
                    compromise. We strive to ensure that our dataset represents
                    the diversity that is our world.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 py-5">
                <div className="box">
                  <div className="circle">
                    <img
                      src={control}
                      loading="lazy"
                      className="control"
                      alt="control"
                    />
                  </div>
                  <h2>Control</h2>
                  <p>
                    Individuals have a genuine choice to decide if and how their
                    data is processed, used, and when it is shared with third
                    parties.
                  </p>
                </div>
              </div>
            </div>
            <div className="row charter-part2 px-5">
              <div className="col-md-6 col-sm-12 py-5">
                <div className="box">
                  <div className="circle">
                    <img src={trust} loading="lazy" alt="trust" />
                  </div>
                  <h2>Transparent</h2>
                  <p>
                    We make sure that individuals understand why we collect and
                    handle personal information, and only collect data that is
                    necessary. When we use artificial intelligence to make
                    automated decisions, we make sure that the algorithms are
                    explainable and fair.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 py-5">
                <div className="box">
                  <div className="circle">
                    <img src={accountable} loading="lazy" alt="Accountable" />
                  </div>
                  <h2>Accountable</h2>
                  <p>
                    We are accountable for the personal information we handle
                    throughout our ecosystem. We vouch to protect and uphold the
                    trust relationship between the company and its customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PDFExport>
      <div className="discount-btn-div container charter-btn">
        <button onClick={exportPDFWithComponent}>Download the charter</button>
      </div>
    </div>
  );
};

export default CharterPanel1;
