import React, { useEffect } from "react";

const PolicyUser = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
        <div className="discounts">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10 col-sm-12 pb-5 discounts-left text-justify">

                        <h3 className="font-weight-bold text-center pb-5">Muna.io Privacy Policy for Users</h3>

                        <div className="terms-section">
                            <p className="subheading">What are your privacy principles?</p>
                            <p className="statement">Your privacy comes first</p>
                            <p>When it comes to collecting, storing and processing your data, we treat your data the way we would want our own data to be treated. We ensure that you have a right to exercise control over what data is collected and sold via our Service. We will never collect, access, use, modify, share, rent or sell your data without your explicit permission. We only process your data in accordance with your instructions, if such processing is necessary to provide the Service to you or to detect, prevent or otherwise address fraud or security issues. We ensure that we have appropriate measures in place to handle your data securely.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">What data do you collect?</p>
                            <p className="statement">We collect browsing history</p>
                            <p>We hold and deal with your data in accordance with our Privacy principles and in accordance with your instructions. All our Services are designed to give you control of your data and profit directly when it is sold, with Muna acting as a marketplace that allows you to do so. We don’t collect, store or use your data without your consent and only access the information we need to function as a secure and distributed data marketplace. We don't use your data for any commercial purposes not related to our Services.</p>
                            <p>Basic Account data. You provide us with details such as your name and email address to create an account which is needed to use our Services. When you open an account you can opt to set up a password recovery email address. We will only use this address to send you a password recovery code if you forget your password.</p>
                            <p>Data you collect using our Services. Our Service allows you to collect a wide range of data about yourself, ranging from electronic communications to your browsing habits and your data from social networking sites (collectively referred to as, "your data"). This may also include additional personal or other information that you agree to provide to us, including your age, gender, or biometric data as well as your data residing on any third-party platforms, such as Facebook ( a “Third-Party Platform”), when you link them to our system.</p>
                            <p>You provide additional information or collect data to increase your data sales revenue. We’ll only deal with this information in accordance with the permissions you set. You can pause data collection at any time, restrict collection from any site and delete any data you do not want to sell. You are in control.</p>
                            <p>Transaction data. We do not process payments from users and instead relies on different payment providers around the world. It is the payment providers that handle and store your credit card details. Your credit card information never reaches our servers. We do not access and do not store your credit card information. We work with payment providers that are completely independent of us. Please study their relevant privacy policies before sharing your financial data available with them.</p>
                            <p>Cross-device functionality. We may also store some aggregated metadata to create features that work across all your devices.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">How is that data being used?</p>
                            <p className="statement">We use it to improve our work</p>
                            <p>Our Services. We will process your data to provide you with our Service. For example, we may use your information to help us maintain your account and improve the Services we provide.</p>
                            <p>Spam and Abuse. To prevent phishing, spam and other kinds of abuse and violations of our Terms of Service, our systems may check your data. If you abuse any of the terms of our Terms of Service, your account may be suspended – temporarily or permanently. You can send an appeal using support@muna.io. In case of more serious violations, your account may be banned. We may also use automated algorithms to analyze the data to stop the abuse.</p>
                            <p>Advanced features. We may use aggregated data about how you use our services to build useful features. For example, when you open the offer menu, we may display the offers you are more likely to accept. To do this, we calculate a rating that shows which offers you are likely to be interested in.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">Do you share my data?</p>
                            <p className="statement">We don’t share your data without your explicit consent.</p>
                            <p>When you sell your data. Except as otherwise stated in this Privacy Policy, we do not collect, store, access, use, sell, rent or share your data without your explicit consent. We may use the Personal Information you provide to us to communicate with you, for example, in response to subheadings you have asked or to provide technical support.</p>
                            <p>We share your data on your behalf whenever a data sale is made. All sales are made with your explicit permission and in accordance with the terms, you set.</p>
                            <p>By agreeing to this Privacy Policy and choosing to sell your data, you are instructing us to transfer your data, on your behalf, to those users in accordance with this Privacy Policy. We employ all appropriate technical and organizational measures (including encryption of your data) to ensure a high level of security for all your data.</p>
                            <p>Our legal obligations. We may access or share your basic profile data when we believe in good faith that disclosure is necessary to establish, protect, or exercise our rights; protect your safety or the safety of others; investigate fraud or other illegal activities, or respond to a government request.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">What are my rights?</p>
                            <p className="statement">Simple to exercise your rights</p>
                            <p>Muna is built to give your complete visibility over your data. Through your account you can:</p>
                            <p>- access all data you collected or sold through Muna</p>
                            <p>- control who has access to which data</p>
                            <p>- delete or amend your personal information</p>
                            <p>- withdraw any consent you’ve previously given us or/and to brands</p>
                            <p>- correct any inaccurate or incomplete personal data we hold on you</p>
                            <p>- lodge a complaint with national data protection authorities regarding our processing of your personal data</p>
                            <p>If you need help in exercising any of these rights, please get in touch with us.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">How can I make a complaint?</p>
                            <p className="statement">We are here to help</p>
                            <p>If you have a complaint, please contact us and we’ll do our best to fix the problem. If you’re still not happy, you can refer your complaint to your relevant national data regulation authority.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading">What about future changes?</p>
                            <p className="statement">We inform and ask permission</p>
                            <p>We’ll post any changes we make to our Privacy Policy on this page and, if they’re significant changes we’ll let you know by email.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicyUser;