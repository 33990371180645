import React from "react";
import RequestDemo from "./RequestDemo";

const CompanyChange = () => {
  return (
    <div className="dataconsumer py-5 container company">
      <h3>THE CHANGE IS HERE</h3>

      <h1>Join us as we build the future with trust and transparency</h1>

      <button data-toggle="modal" data-target="#myModal">
        Request a demo
      </button>

      <RequestDemo />
    </div>
  );
};

export default CompanyChange;
