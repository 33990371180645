import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

const CharterTablePanel3 = () => {
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    db.collection("contacts").onSnapshot((snapshot) => {
      const newContact = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setContactList(newContact);
    });
  }, []);
  return (
    <div className="benefits charter-panel1">
      <div className="container py-5">
        <table className="table  table-borderless">
          <thead className="table-head">
            <tr>
              <th scope="col">First name</th>
              <th scope="col">Last name</th>
              <th scope="col">Organization</th>
              <th scope="col">Country</th>
            </tr>
          </thead>

          <tbody>
            {contactList.map((data) => {
              return (
                <tr key={data.id}>
                  <td>
                    {data.displayName ? data.firstName : data.firstName[0]}
                  </td>
                  <td>{data.displayName ? data.lastName : data.lastName[0]}</td>
                  <td>
                    {data.displayName
                      ? data.organization
                      : data.organization[0]}
                  </td>
                  <td>{data.country}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CharterTablePanel3;
