import React from "react";
import sanna from "../../assets/sanna2.jpg";
import denny from "../../assets/denny2.jpg";
// import ali from "../../assets/Ali_.jpeg";
import akhil from "../../assets/Akhil.jpeg";
import maria from "../../assets/Maria.jpg";
// import isabella from "../../assets/isabella_.jpeg";
import harshit from "../../assets/harshit.jpeg";
// import mikael from "../../assets/mikael.jpg";
import lyn from "../../assets/lyn.jpg";

const AboutPanel4 = () => {
  return (
    <div id="benefits" className="aboutpanel4">
      <div>
        <main className="container py-5">
          <h1>Our Team</h1>
          <div className="row justify-content-around">
            <div className="col-md-4 col-sm-12 py-5">
              <div className="circle">
                <img src={sanna} loading="lazy" alt="sanna" />
                <div className="aboutpanel4-name">
                  <h2>Sanna Toropainen</h2>
                </div>
                <div className="aboutpanel4-job">
                  <h3>Co-founder, CEO</h3>
                </div>
                <div className="aboutpanel4-smedia">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <a
                        aria-label="linkedin"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/sanna-toropainen/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        aria-label="envelope"
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:sanna@muna.io"
                      >
                        <i className="fas fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 py-5">
              <div className="circle">
                <img src={denny} loading="lazy" alt="denny" />
                <div className="aboutpanel4-name">
                  <h2>Denny Wong</h2>
                </div>
                <div className="aboutpanel4-job">
                  <h3>Cofounder, CPO & CSO</h3>
                </div>
                <div className="aboutpanel4-smedia">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <a
                        aria-label="twitter"
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/dewotak?lang=en"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        aria-label="linkedin"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/dennywong/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>

                    <li className="list-inline-item">
                      <a
                        aria-label="envelope"
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:denny@muna.io"
                      >
                        <i className="fas fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 py-5">
              <div className="circle">
                <img src={harshit} loading="lazy" alt="harshit" style={{objectFit:'cover'}} />
                <div className="aboutpanel4-name">
                  <h2>Harshit Singh</h2>
                </div>
                <div className="aboutpanel4-job">
                  <h3>Full Stack Engineer</h3>
                </div>
                <div className="aboutpanel4-smedia">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <a
                        aria-label="linkedin"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/harshit-singh-29a980115/"
                        >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="col-md-4 col-sm-12 akil-img py-5">
              <div className="circle">
                <img src={akhil} loading="lazy" alt="akhil" />
                <div className="aboutpanel4-name">
                  <h2>Akhil Rajan</h2>
                </div>
                <div className="aboutpanel4-job">
                  <h3>Data Science Engineer</h3>
                </div>
                <div className="aboutpanel4-smedia">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <a
                        aria-label="linkedin"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/akhilpeterrajan/"
                        >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 py-5">
              {/* <div className="circle">
                <img src={ali} className="img-ali" loading="lazy" alt="ali" />
                <div className="aboutpanel4-name">
                  <h2>Ali Khalife</h2>
                </div>
                <div className="aboutpanel4-job">
                  <h3>Data Protection Assistant</h3>
                </div>
                <div className="aboutpanel4-smedia">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <a
                        aria-label="linkedin"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/ali-khalife-a844b6136/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="circle">
                <img src={maria} className="img-maria" loading="lazy" alt="maria" />
                <div className="aboutpanel4-name">
                  <h2>Maria Jose Marin Rodrigues</h2>
                </div>
                <div className="aboutpanel4-job">
                  <h3>Data Protection Advisor</h3>
                </div>
                <div className="aboutpanel4-smedia">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <a
                        aria-label="linkedin"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/maria-jose-marinr/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 py-5">
              <div className="circle">
                <img src={lyn} loading="lazy" alt="lyn" />
                <div className="aboutpanel4-name last-name">
                  <h2>Lyn Stickelmann</h2>
                </div>
                <div className="aboutpanel4-job last-job">
                  <h3>Social Media Intern</h3>
                </div>
                <div className="aboutpanel4-smedia">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <a
                        aria-label="linkedin"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/lyn-stickelmann-831220219/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4 col-sm-12 isabella-img">
              <div className="circle">
                <img src={isabella} loading="lazy" alt="isabella" />
                <div className="aboutpanel4-name">
                  <h2>Isabella Pratita</h2>
                </div>
                <div className="aboutpanel4-job">
                  <h3>Marketing</h3>
                </div>
                <div className="aboutpanel4-smedia">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <a
                        aria-label="linkedin"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/isabellapratita/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            
          </div>
          <div className="row justify-content-around align-content-center">
            
            {/* <div className="col-md-4 col-sm-12">
              <div className="circle">
                <img src={mikael} loading="lazy" alt="mikael" />
                <div className="aboutpanel4-name last-name">
                  <h2>Mikael Huuhtanen</h2>
                </div>
                <div className="aboutpanel4-job last-job">
                  <h3>Social Media Intern</h3>
                </div>
                <div className="aboutpanel4-smedia">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <a
                        aria-label="linkedin"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/mikael-huuhtanen-235a40212/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-md-4 col-sm-12">
              <div className="discount-btn-div container">
                <a
                  aria-label="linkedin"
                  target="_blank"
                  rel="noreferrer"
                  href=" https://angel.co/company/muna-io/jobs"
                >
                  <button>Join our team!</button>
                </a>
              </div>
            </div>
            {/* <div className="col-md-4 col-sm-12"></div> */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AboutPanel4;
