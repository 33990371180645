import React, { useState } from "react";
import muna from "../../assets/mockupmobile.png";
import DownloadModal from "./DownloadModal";
import { isAndroid, isIOS } from "react-device-detect";

const Discounts = () => {
  const [hoverStyle, setHoverStyle] = useState({ visibilty: "hidden" });
  const detectDevice = isAndroid ? "android" : isIOS ? "IOS" : "web";
  const renderDeviceDetect = {
    ios: (
      <div className="discount-btn-div container charter-btn">
        <a
          aria-label="AppDownloadIOS"
          target="_blank"
          rel="noreferrer"
          href={ process.env.REACT_APP_APP_STORE_LINK }
          onClick="ga('send', 'event', { eventCategory: 'AppDownloaIOS', eventAction: 'click', eventLabel: 'MunaApp', eventValue: 1});"
        >
          <button>
            Download <p>to IPhone</p>
          </button>
        </a>
      </div>
    ),
    android: (
      <div className="discount-btn-div container charter-btn">
        <a
          aria-label="AppDownloadAndroid"
          target="_blank"
          rel="noreferrer"
          href={ process.env.REACT_APP_GOOGLE_PLAY_STORE_LINK }
          onClick="ga('send', 'event', { eventCategory: 'AppDownloadAndroid', eventAction: 'click', eventLabel: 'MunaApp', eventValue: 1});"
        >
          <button>
            Download <p>to Android</p>
          </button>
        </a>
      </div>
    ),
    web: (
      <div div className="row py-5">
        <div className="minifab col-md-6 ">
          <div className="discount-btn-div container charter-btn">
            <a
              aria-label="AppDownloadIOS"
              target="_blank"
              rel="noreferrer"
              href={ process.env.REACT_APP_APP_STORE_LINK }
              onClick="ga('send', 'event', { eventCategory: 'AppDownloaIOS', eventAction: 'click', eventLabel: 'MunaApp', eventValue: 1});"
            >
              <button>
                Download <p>to IPhone</p>
              </button>
            </a>
          </div>
        </div>
        <div className="minifab col-md-6">
          <div className="discount-btn-div container charter-btn">
            <a
              aria-label="AppDownloadAndroid"
              target="_blank"
              rel="noreferrer"
              href={ process.env.REACT_APP_GOOGLE_PLAY_STORE_LINK }
              onClick="ga('send', 'event', { eventCategory: 'AppDownloadAndroid', eventAction: 'click', eventLabel: 'MunaApp', eventValue: 1});"
            >
              <button>
                Download <p>to Android</p>
              </button>
            </a>
          </div>
        </div>
      </div>
    ),
  };
  return (
    <div className="discounts">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-12  discounts-left">
            <div className="discounts-about-left-box">
              <h1>Get discounts with your data</h1>
              <p>
                On our app you get the best deals in exchange for your data.
                Find the best offers, share your data like an email address, and
                get the discount. Also, when you want you can stop sharing -
                anytime.
              </p>

              <div className="download" style={hoverStyle}>
                <i
                  className="fas fa-times-circle"
                  style={{ float: "right", fontSize: 20 }}
                  onClick={(e) => {
                    setHoverStyle({ visibility: "hidden" });
                  }}
                ></i>
                {(() => {
                  switch (detectDevice) {
                    case "IOS":
                      return renderDeviceDetect.ios;
                    case "android":
                      return renderDeviceDetect.android;
                    default:
                      return renderDeviceDetect.web;
                  }
                })()}
              </div>

              <div className="discount-btn-div container">
                <button
                  // data-toggle="modal"
                  // data-target="#downloadModal"
                  onClick={(e) => {
                    setHoverStyle({ visibility: "visible" });
                  }}
                >
                  Download
                </button>
              </div>

              <DownloadModal />
            </div>
          </div>
          <div className="col-md-7 col-sm-12 discount-img-box">
            <img
              className="discount-img"
              loading="lazy"
              src={muna}
              alt="muna.io"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discounts;
