import React, { useEffect } from "react";

const PolicyCompany = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
        <div className="discounts">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10 col-sm-12 pb-5 discounts-left text-justify">

                        <h3 className="font-weight-bold text-center pb-5">Muna.io Privacy Policy for Companies</h3>

                        <div className="terms-section">
                            <p className="subheading">Dear Muna.io customer,</p>
                            <p>This page is under construction.</p>
                            <p>We will soon be updating the privacy-policy.</p>
                        </div>
                        <div className="terms-section">
                            <p className="subheading"></p>
                            <p></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicyCompany;