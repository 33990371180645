import React, { useState } from "react";
import DownloadModal from "./DownloadModal";
import { isAndroid, isIOS } from "react-device-detect";

const DataConsumer = () => {
  const [hoverStyle, setHoverStyle] = useState({ visibilty: "hidden" });
  const detectDevice = isAndroid ? "android" : isIOS ? "IOS" : "web";
  const renderDeviceDetect = {
    ios: (
      <div className="discount-btn-div container charter-btn">
        <a
          aria-label="AppDownloadIOS"
          target="_blank"
          rel="noreferrer"
          href={ process.env.REACT_APP_APP_STORE_LINK }
          onClick="ga('send', 'event', { eventCategory: 'AppDownloaIOS', eventAction: 'click', eventLabel: 'MunaApp', eventValue: 1});"
        >
          <button>
            Download <p>to IPhone</p>
          </button>
        </a>
      </div>
    ),
    android: (
      <div className="discount-btn-div container charter-btn">
        <a
          aria-label="AppDownloadAndroid"
          target="_blank"
          rel="noreferrer"
          href={ process.env.REACT_APP_GOOGLE_PLAY_STORE_LINK }
          onClick="ga('send', 'event', { eventCategory: 'AppDownloadAndroid', eventAction: 'click', eventLabel: 'MunaApp', eventValue: 1});"
        >
          <button>
            Download <p>to Android</p>
          </button>
        </a>
      </div>
    ),
    web: (
      <div div className="row py-5">
        <div className="minifab col-md-6 ">
          <div className="discount-btn-div container charter-btn">
            <a
              aria-label="AppDownloadIOS"
              target="_blank"
              rel="noreferrer"
              href={ process.env.REACT_APP_APP_STORE_LINK }
              onClick="ga('send', 'event', { eventCategory: 'AppDownloaIOS', eventAction: 'click', eventLabel: 'MunaApp', eventValue: 1});"
            >
              <button>
                Download <p>to IPhone</p>
              </button>
            </a>
          </div>
        </div>
        <div className="minifab col-md-6">
          <div className="discount-btn-div container charter-btn">
            <a
              aria-label="AppDownloadAndroid"
              target="_blank"
              rel="noreferrer"
              href={ process.env.REACT_APP_GOOGLE_PLAY_STORE_LINK }
              onClick="ga('send', 'event', { eventCategory: 'AppDownloadAndroid', eventAction: 'click', eventLabel: 'MunaApp', eventValue: 1});"
            >
              <button>
                Download <p>to Android</p>
              </button>
            </a>
          </div>
        </div>
      </div>
    ),
  };

  return (
    <div className="dataconsumer py-5 container">
      <h1>
        You can do what you want with your data. Sell it, share it or delete it.
      </h1>
      <div className="download download-part2" style={hoverStyle}>
        <i
          className="fas fa-times-circle"
          style={{ float: "right", fontSize: 20 }}
          onClick={(e) => {
            setHoverStyle({ visibility: "hidden" });
          }}
        ></i>
        {(() => {
          switch (detectDevice) {
            case "IOS":
              return renderDeviceDetect.ios;
            case "android":
              return renderDeviceDetect.android;
            default:
              return renderDeviceDetect.web;
          }
        })()}
      </div>

      <button
        // data-toggle="modal"
        // data-target="#downloadModal"
        onClick={(e) => {
          setHoverStyle({ visibility: "visible" });
        }}
      >
        Download
      </button>

      <DownloadModal />
    </div>
  );
};

export default DataConsumer;
