import React from "react";
import { Link } from "react-router-dom";

import apple from "../assets/apple-app-store.svg";
import playstore from "../assets/google-play-store.svg";
import FooterModal from "./FooterModal";

const Footer = () => {
  return (
    <footer className="navbar-light bg-light ">
      <div className="container">
        <div className="row justify-content-around">
          
          <div className="col-md-auto footer-box">
            {/*  MEdia */}
            <div className="row justify-content-around">
              <div className="column">
                <ul className="list-inline ">
                  <li className="list-inline-item">
                    <a
                      aria-label="facebook"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/MunaDataTravel/"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      aria-label="twitter"
                      target="_blank"
                      rel="noreferrer"
                      href=" https://twitter.com/MunaDatamarket"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>

                  <li className="list-inline-item">
                    <a
                      aria-label="medium"
                      target="_blank"
                      rel="noreferrer"
                      href="https://sannatoro.medium.com/"
                    >
                      <i className="fab fa-medium-m"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      aria-label="linkedin"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/munaapp/"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>

                  <li className="list-inline-item">
                    <a
                      aria-label="instagram"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/muna.io/"
                    >
                      <i className="fab fa-instagram-square"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      aria-label="youtube"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.youtube.com/channel/UCZz-MOjIoYOOOYpiNNLEJPw"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
                <div className="Copyright-box">
                  <p className="copyright quick-links">
                    &copy; Copyright {new Date().getFullYear()} Muna.io
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-auto footer-box footer-middle py-5 ">
            <div className="column">
              <div className="col container">
                <p className="row justify-content-center align-items-center">
                  <span className="mx-2">Contact:</span>
                  <span className="mx-2">
                    <a
                      aria-label="envelope"
                      target="_blank"
                      rel="noreferrer"
                      href="tel:+32 479 36 32 79"
                      >
                      <i className="fas fa-phone-square"></i> +32 479 36 32 79
                    </a>
                  </span>
                  <span className="mx-2">
                      <a
                        aria-label="envelope"
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:denny@muna.io"
                        >
                        <i className="fas fa-envelope"></i> denny@muna.io
                      </a>
                    </span>
                </p>
              </div>
              <div className="col">
                <ul className="list-inline quick-links">
                  <li className="list-inline-item">
                    <Link
                      className="policy-footer"
                      to="policy"
                      offset={150}
                      rel="noopener"
                    >
                      Privacy
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link aria-label="terms" rel="noreferrer" to="terms">
                      Terms
                    </Link>
                  </li>

                  <li className="list-inline-item">
                    <Link aria-label="instagram" rel="noreferrer" to="sitemap">
                      Sitemap
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-auto footer-box footer-box-img  ">
            <div className="row justify-content-around">
              <div className="column">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a
                      aria-label="AppDownloadIOS"
                      target="_blank"
                      rel="noreferrer"
                      href={ process.env.REACT_APP_APP_STORE_LINK }
                      onClick="ga('send', 'event', { eventCategory: 'AppDownloaIOS', eventAction: 'click', eventLabel: 'MunaApp', eventValue: 1});"
                    >
                      <img src={apple} alt="apple-store" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      aria-label="AppDownloadAndroid"
                      target="_blank"
                      rel="noreferrer"
                      href={ process.env.REACT_APP_GOOGLE_PLAY_STORE_LINK }
                      onClick="ga('send', 'event', { eventCategory: 'AppDownloadAndroid', eventAction: 'click', eventLabel: 'MunaApp', eventValue: 1});"
                    >
                      <img src={playstore} alt="goole-play-store" />
                    </a>
                  </li>
                </ul>
                <div className="footer-btn">
                  <button data-toggle="modal" data-target="#footerModal">
                    Sign up for Newsletter
                  </button>
                </div>
              </div>
            </div>
          </div>
          
          <FooterModal />
        
        </div>
      </div>
    </footer>
  );
};

export default Footer;
