import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import CharterPanel1 from "../components/charter/CharterPanel1";
import CharterFormPanel2 from "../components/charter/CharterFormPanel2";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CharterTablePanel3 from "../components/charter/CharterTablePanel3";

const About = ({ isTabletOrMobile }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="about container">
      <Helmet>
        <title> Muna charter | Ethical data marketplace | Muna</title>
        <meta
          name="description"
          content="Support our charter. Sign it. Let us work together to build a future where trust, transparency, ethical data collection, and fair data pricing for individuals are a norm and not an exception."
        />
        <link rel="canonical" href="https://Muna.io/Muna-Charter" />
        {/* <!-- Google / Search Engine Tags --> */}
        <meta
          itemprop="name"
          content="Muna charter | Ethical data marketplace | Muna"
        />
        <meta
          itemprop="description"
          content="Support our charter. Sign it. Let us work together to build a future where trust, transparency, ethical data collection, and fair data pricing for individuals are a norm and not an exception."
        />
        <meta
          itemprop="image"
          content="http://static.wixstatic.com/media/66c2f5_d216e103469a4625b69b570d3c6b6da3~mv2.png/v1/fill/w_1206,h_902,al_c/66c2f5_d216e103469a4625b69b570d3c6b6da3~mv2.png"
        />
      </Helmet>
      {isTabletOrMobile && <Navbar />}
      <CharterPanel1 />
      <CharterFormPanel2 />
      <CharterTablePanel3 />

      {isTabletOrMobile && <Footer />}
    </div>
  );
};

export default About;
