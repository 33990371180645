import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import firebase from "firebase";

const CharterFormPanel2 = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [keepInformed, setKeepInformed] = useState(false);
  const [displayName, setDisplayName] = useState(true);

  const [countData, setCountData] = useState();
  const [targetData, setTargetData] = useState();
  const getCountData = () => {
    db.collection("contacts")
      .get()
      .then(function (querySnapshot) {
        setCountData(querySnapshot.size);
        setTargetData(Math.ceil(querySnapshot.size / 100) * 100);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection("contacts")
      .add({
        firstName: firstName,
        lastName: lastName,
        country: country,
        email: email,
        organization: organization || "",
        keepInformed: keepInformed,
        displayName: displayName,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        alert("Your message has been submitted👍");
      })
      .catch((error) => {
        alert(error.message);
      });

    setFirstName("");
    setLastName("");
    setCountry("");
    setEmail("");
    setOrganization("");

    getCountData();
  };
  useEffect(() => {
    getCountData();
  }, [countData]);
  return (
    <div className="charterpanel2 py-5">
      <div className="container ">
        <div className="charter-form">
          <div className="center_div">
            <form className="py-5 px-5" onSubmit={handleSubmit}>
              <p className="py-5">
                {countData} have signed. <span> Let's get to</span> {targetData}
                <span>!</span>
              </p>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group col-md-8 ">
                    <label htmlFor="first">First Name</label>
                    <input
                      required
                      value={firstName}
                      type="text"
                      className="form-control"
                      placeholder=""
                      id="first"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                {/* col-md-6   */}

                <div className="col-md-6">
                  <div className="form-group col-md-8">
                    <label htmlFor="last">Last Name</label>
                    <input
                      required
                      value={lastName}
                      type="text"
                      className="form-control"
                      placeholder=""
                      id="last"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                {/* <!--  col-md-6   --> */}
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group col-md-8">
                    <label htmlFor="email">Email address</label>
                    <input
                      required
                      value={email}
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                {/* <!--  col-md-6   --> */}

                <div className="col-md-6">
                  <div className="form-group col-md-8">
                    <label htmlFor="country">Country</label>
                    <input
                      required
                      value={country}
                      type="text"
                      className="form-control"
                      placeholder=""
                      id="country"
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                </div>
                {/* <!--  col-md-6   --> */}
              </div>
              {/* <!--  row   --> */}

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group col-md-8">
                    <label htmlFor="organization">Organization</label>
                    <input
                      value={organization}
                      type="text"
                      className="form-control"
                      placeholder=""
                      id="organization"
                      onChange={(e) => setOrganization(e.target.value)}
                    />
                  </div>
                </div>
                {/* <!--  col-md-6   --> */}

                <div className="col-md-6"></div>
                {/* <!--  col-md-6   --> */}
              </div>
              {/* <!--  row   --> */}

              <div className="radio  row">
                <label className="radio-label">
                  <input
                    type="checkbox"
                    name="keep-info"
                    id="keep-info"
                    value={keepInformed}
                    onChange={() => setKeepInformed(!keepInformed)}
                  />
                  <span>Keep me informed about this Charter</span>
                </label>
              </div>
              <div className="radio row ">
                <label className="radio-label ">
                  <input
                    type="checkbox"
                    name="display-name"
                    id="display-name"
                    value={displayName}
                    onChange={() => setDisplayName(!displayName)}
                  />
                  <span>Do not display my name on this webpage.</span>
                </label>
              </div>
              <div className=" row py-4">
                <button type="submit" className="btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharterFormPanel2;
